import React, { useEffect } from "react"
import Layout from "../../components/Layout"
 
import OhmConnect from "../../components/OhmConnect"
export default function TrackHero() {
  
  

  return (
    <>
      <Layout>
        <OhmConnect />
      </Layout>
      
    </>
  )
}
