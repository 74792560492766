export const techTags = [
  {
    id: 1,
    name: "Product Design",
  },
  {
    id: 2,
    name: "Design Sprint",
  },
  {
    id: 3,
    name: "Front-End",
  },
  {
    id: 4,
    name: "Back-End",
  },
  {
    id: 5,
    name: "DevOps",
  },
]

export const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "GreenTech, Energy & Utilities, Natural Resources, Energy",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2B SaaS, B2C",
  },
  {
    id: 3,
    heading: "Our Work",
    description: "IT Staff Augmentation, Custom Software Development",
  },
]

export const techImages = [
  {
    id: 1,
    icon: "/caseStudy/Ohmconnect/tech1.svg",
  },
  {
    id: 2,
    icon: "/caseStudy/Ohmconnect/tech2.svg",
  },
  {
    id: 3,
    icon: "/caseStudy/Ohmconnect/tech3.svg",
  },
  {
    id: 4,
    icon: "/caseStudy/Ohmconnect/tech4.svg",
  },
  {
    id: 5,
    icon: "/caseStudy/Ohmconnect/tech5.svg",
  },
  {
    id: 6,
    icon: "/caseStudy/Ohmconnect/tech6.svg",
  },
  {
    id: 7,
    icon: "/caseStudy/Ohmconnect/tech7.svg",
  },
  {
    id: 8,
    icon: "/caseStudy/Ohmconnect/tech8.svg",
  },
  {
    id: 9,
    icon: "/caseStudy/Ohmconnect/tech9.svg",
  },
  {
    id: 10,
    icon: "/caseStudy/Ohmconnect/tech10.svg",
  },
  {
    id: 11,
    icon: "/caseStudy/Ohmconnect/tech11.svg",
  },
  {
    id: 12,
    icon: "/caseStudy/Ohmconnect/tech12.svg",
  },
  {
    id: 13,
    icon: "/caseStudy/Ohmconnect/tech13.svg",
  },
  {
    id: 14,
    icon: "/caseStudy/Ohmconnect/tech14.svg",
  },
  {
    id: 15,
    icon: "/caseStudy/Ohmconnect/tech15.svg",
  },
  {
    id: 16,
    icon: "/caseStudy/Ohmconnect/tech16.svg",
  },
  {
    id: 17,
    icon: "/caseStudy/Ohmconnect/tech17.svg",
  },
  {
    id: 18,
    icon: "/caseStudy/Ohmconnect/tech18.svg",
  },
]

export const aboutProjectIcons = [
  { id: 1, icon: "/caseStudy/Ohmconnect/about1.svg" },
  { id: 2, icon: "/caseStudy/Ohmconnect/about2.svg" },
  { id: 3, icon: "/caseStudy/Ohmconnect/about3.svg" },
]

export const keyChallenges = [
  {
    id: 1,
    title: "Legacy App Maintenance & Upgrading to SPA",
    para: "The main challenge was to identify and address errors in the web application in use, which required bug fixing and feature enhancement. Furthermore, designing a unique, more engaging, and a seamless experience for the users was also crucial.",
  },
  {
    id: 2,
    title: "Device Integrations",
    para: "The challenge was to integrate various smart energy monitoring devices with the Energy Saving Platform's system, allowing users to register their devices and earn rewards for saving energy.",
  },
  {
    id: 3,
    title: "Incentivized Energy Saving Plan",
    para: "To create an internal tool for managing energy-saving events and messages, and integrating APIs for SMS and email services to fine-tune the way Energy Saving Platform communicates with its users.",
  },
  {
    id: 4,
    title: "Enabling a Rapid Go-To-Market Presence",
    para: "To enable a rapid go-to-market presence by building a fresh, new website for the Energy Saving Platform's Texas branch, engaging potential clients and users, and successfully launching the site within a month.",
  },
]

export const solutions = [
  {
    id: 1,
    heading: "Legacy App Upgrade to Single Page Application",
    description:
      "Identified and addressed errors in the legacy app and upgraded it to a SPA",
    icon: "/caseStudy/Ohmconnect/solution1.svg",
  },
  {
    id: 2,
    heading: "Seamless Device Integrations",
    description:
      "Integrated device interfaces and developed a smart device interface for air conditioning units",
    icon: "/caseStudy/Ohmconnect/solution2.svg",
  },
  {
    id: 3,
    heading: "Website Design And Development",
    description:
      "Built a new website using WebFlow to enable rapid go-to-market presence for the Energy Saving Platform",
    icon: "/caseStudy/Ohmconnect/solution3.svg",
  },
  {
    id: 4,
    heading: "Payout Fraud Checking and Dynamic Data Filtering",
    description:
      "Worked on payout fraud checking algorithms for Origin-Spike rewards program and implemented dynamic data filtering using Twilio API",
    icon: "/caseStudy/Ohmconnect/solution4.svg",
  },
  {
    id: 5,
    heading:
      "Internal Tool for Managing OhmHour Events with Third-Party API Integration",
    description:
      "Developed an internal tool for managing OhmHour events and integrated Twilio's API for SMS services and Active Campaign's API for email services",
    icon: "/caseStudy/Ohmconnect/solution5.svg",
  },
]

export const featuresList = [
  {
    id: 1,
    feature: "Connect with your utility company",
    icon: "/caseStudy/Ohmconnect/feature1.svg",
    icon2: "/caseStudy/Ohmconnect/feature11.svg",
    image: "/caseStudy/Ohmconnect/featurebg1.png",
    paragraph:
      "The platform allows you to connect with your utility company, making it easy to save energy and reduce your electricity bill.",
  },
  {
    id: 2,
    feature: "Smart device integration",
    icon: "/caseStudy/Ohmconnect/feature2.svg",
    icon2: "/caseStudy/Ohmconnect/feature12.svg",
    image: "/caseStudy/Ohmconnect/featurebg2.png",
    paragraph:
      "The platform offers smart device integration for electronic units, so you can control your devices remotely and save energy.",
  },
  {
    id: 3,
    feature: "OhmHour Events",
    icon: "/caseStudy/Ohmconnect/feature3.svg",
    icon2: "/caseStudy/Ohmconnect/feature13.svg",
    image: "/caseStudy/Ohmconnect/featurebg3.png",
    paragraph:
      "Participate in OhmHour events and earn rewards for reducing energy use during peak hours.",
  },
  {
    id: 4,
    feature: "Spin Rewards",
    icon: "/caseStudy/Ohmconnect/feature4.svg",
    icon2: "/caseStudy/Ohmconnect/feature14.svg",
    image: "/caseStudy/Ohmconnect/featurebg4.png",
    paragraph:
      "Users can share their rewards and achievements on social media platforms, creating a sense of community and encouraging engagement.",
  },
]

export const resultsList = [
  {
    id: 1,
    heading: "Enhanced User Experience",
    description:
      "By upgrading the legacy app to a single-page application, the platform offers a seamless and intuitive interface, resulting in a more engaging experience for users",
    icon: "/caseStudy/Ohmconnect/result1.svg",
  },
  {
    id: 2,
    heading: "Improved Communication",
    description:
      "The integration of Twilio's SMS and Active Campaign's email services ensures efficient and effective communication with users, enabling the client to keep them informed about rewards, events, and other updates",
    icon: "/caseStudy/Ohmconnect/result2.svg",
  },
  {
    id: 3,
    heading: "Streamlined Management",
    description:
      "The internal tool developed for managing OhmHour events and the Origin-Spike rewards program has made it easy to identify and reward legitimate users, ensuring fair and efficient management of the system",
    icon: "/caseStudy/Ohmconnect/result3.svg",
  },
  {
    id: 4,
    heading: "Rapid Go-to-Market Presence",
    description:
      "The new website built using WebFlow enabled the platform to establish a strong online presence within a short period, resulting in increased visibility and reach",
    icon: "/caseStudy/Ohmconnect/result4.svg",
  },
]

export const businessNeedsList = [
  {
    id: 1,
    heading: "Improved user experience and engagement",
    description: "",
    icon: "/caseStudy/Ohmconnect/business1.svg",
  },
  {
    id: 2,
    heading: "Efficient communication with users",
    description: "",
    icon: "/caseStudy/Ohmconnect/business2.svg",
  },
  {
    id: 3,
    heading: "Effective energy consumption management and rewards program",
    description: "",
    icon: "/caseStudy/Ohmconnect/business3.svg",
  },
  {
    id: 4,
    heading: "Rapid go-to-market presence",
    description: "",
    icon: "/caseStudy/Ohmconnect/business4.svg",
  },
  {
    id: 5,
    heading: "Better resource allocation and utilization",
    description: "",
    icon: "/caseStudy/Ohmconnect/business5.svg",
  },
]
